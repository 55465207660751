


html{
    padding: 40px 12px;
    background: linear-gradient(135deg, #fff3fc 0%, #f5fdff 100%);
}
@media screen and (max-width:640px) { 
    html{
        padding: 16px 8px;
    }
    /*　画面サイズが480pxからはここを読み込む　*/
    
}
.aboveCon{
    max-width: 1020px;
    margin: 0 auto;
}
.skillsWrap{
    display: flex;
    align-items: center ;
    height: 100%;
}
.skillList{
    display: flex;
    flex-wrap: wrap;
    justify-content: flex-end;
    li{
        width: auto;
        padding: 6px 8px;
    }
}

.pagenation {
    margin-bottom:16px;
    margin-top:16px;
    ul{
        justify-content:flex-end;
    }
}
.text{
    background: 
    rgba(255,255,255,.7);
    padding: 12px 10px;
    font-size: 15px;
    letter-spacing: 0.06em;
    line-height: 1.6;
}
.eventNone{
    pointer-events: none;
}
.modalWrap{
    position:relative;
    // width:80%;
    img{
        // pointer-events: all;
    }
    button {
        cursor:pointer;
        pointer-events: all;
        top:50vh;
        z-index:1;
        position:fixed;
        background:rgba(255,255,255,.4);
        filter: drop-shadow(0px 0px 1px rgba(255, 255, 255, 1));
        &.prev{
            transform: translateX(-50%) rotate(180deg);
            left:9.4%;
        }
        &.next{
            transform: translateX(50%);
            right:9.4%;
        }
    }
    .closeIcon{
        right: 1%;
        top: 1%;
        position: fixed;
        cursor: pointer;  
        transition: 0.8s;
        opacity: 0.8;  
        &:hover{
            opacity: 0.5;
        }
    }
}
// .hide{
//     // display:none;
//     opacity:0;
//     transition-duration: 0.5s;
// }
// .display{
//     // display:block;
//     opacity:1;
//     transition-duration: 0.5s;
// }